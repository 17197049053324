import React from 'react'
import Icon from '../Icon'
import Link from 'next/link'
import styles from '../../styles/Home.module.css'
import Logo from '../../../public/WebHR.svg'
import { Container, Row, Col } from 'react-bootstrap'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import footerBottomMenu from '../../data/footerBottomMenu'
import Script from 'next/script'
const Footer = () => {
  
  return (
    <>
      <Script 
        id='tawkid'
        strategy="lazyOnload"
        type="text/javascript" 
        async
      >
        {
          `
          var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
          (function(){
          var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
          s1.async=true;
          s1.src='https://embed.tawk.to/5f3cca25cc6a6a5947acf619/default';
          s1.charset='UTF-8';
          s1.setAttribute('crossorigin','*');
          s0.parentNode.insertBefore(s1,s0);
          })();
          `
        }
      </Script>
      <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="CookieConsent"
      onAccept={() => {
        Cookies.set('CookieConsent', '1')
      }}
      style={{
        background: 'linear-gradient(28.37deg,#3f4a55 160.42%,#708396 50.81%)',
        justifyContent: 'center',
      }}
      buttonStyle={{
        background: '#1369B2',
        color: '#fff',
        fontSize: '13px',
        margin: '7px',
        borderRadius: '4px',
        fontWeight: '600',
      }}
      expires={1}
      contentStyle={{ flex: 'inherit', margin: '7px', textAlign: 'center' }}
      >
        This website uses cookies for better browsing experience. By continuing to use our website, you agree to our use of cookies in accordance with our privacy policy.
      </CookieConsent>
      <div className={styles.footer}>
        <Container>
          <Row style={{paddingTop:50}}>
            <Col style={{marginBottom:10}} lg={6}>
              <div className={styles.logofooter}>
                <Logo style={{ width: '100px', height: 'auto' }} />
              </div>
              <p style={{width:"95%", color:"#484c50"}}  >WebHR strives to exceed the client&apos;s expectations and continue to evolve our offering to stay ahead of the curve in the HR technology space.</p>
              <p style={{color:"#484c50"}}>39111 Paseo Padre Parkway, Fremont, California, 94538, USA</p>
              <div style={{ display: 'flex', flexWrap:"wrap",  margin:5, marginBottom:10, gap:"5px"}}>
                <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/Footer1.svg" alt='aicpa webhr' style={{ marginRight: 10, width:80, height:80 }} />
                <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/Footer2.svg" alt="cdpr webhr" style={{ marginRight: 10,width:80, height:80 }} />
                <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/Footer3.svg" alt="ccpa webhr" style={{ marginRight: 10, width:80, height:80 }} />
                <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/iso.svg" alt="iso 27001 webhr" style={{ marginRight: 10, width:80, height:80 }} />
                <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/iso27701.svg" alt="iso 27701 webhr" style={{ marginRight: 10, width:80, height:80 }} />
              </div>
            </Col>
            {/* <Col  sm={4} md={4} lg={2}></Col> */}
            <Col  sm={4} md={4} lg={2}>
                <p style={{letterSpacing:"1px"}} className={styles.headingsFooter}>Pages</p>
                <ul className={styles.footerUl}>
                  <li>
                    <Link href="/about" style={{ padding: '1em 0' }}>
                      <h2 style={{}}>About WebHR</h2>
                    </Link>
                  </li>
                  <li>
                    <Link href="/pricing" style={{ padding: '1em 0' }}>
                      <h2>Pricing and Signup</h2>
                    </Link>
                  </li>
                  <li>
                    <Link href="/privacy" style={{ padding: '1em 0' }}>
                      <h2> Privacy Policy </h2>
                    </Link>
                  </li>
                  <li>
                    <Link href="/termsconditions" style={{ padding: '1em 0' }}>
                    <h2> Terms & Conditions </h2>
                    </Link>
                  </li>
                  <li>
                    <Link href="/contact" style={{ padding: '1em 0' }}>
                      <h2> Contact Us </h2>
                    </Link>
                  </li>
                  <li>
                    <Link href="/partner-with-us" style={{ padding: '1em 0' }}>
                      <h2> Partner with Us </h2>
                    </Link>
                  </li>
                </ul>
            </Col>
            <Col sm={4} md={4} lg={2}>
              <p style={{letterSpacing:"1px"}} className={styles.headingsFooter}>Support</p>
              <ul className={styles.footerUl}>
                <li>
                  <a href="/faqs" rel="noreferrer" style={{ padding: '1em 0' }}>
                    <h2> FAQs </h2>
                  </a>
                </li>

                <li>
                  <a href="/security" rel="noreferrer" style={{ padding: '1em 0' }}>
                  <h2>Data Security</h2> 
                  </a>
                </li>
              </ul>
              <p className={styles.headingsFooter}>Resources</p>
              <ul className={styles.footerUl}>
                <li>
                  <a href="/glossary" style={{ padding: '1em 0' }}>
                    <h2> Glossary </h2>
                  </a>
                </li>

                <li>
                  <a href="/contents" style={{ padding: '1em 0' }}>
                  <h2>Content Library</h2> 
                  </a>
                </li>

                <li>
                  <a href="/blog" style={{ padding: '1em 0' }}>
                  <h2>Blog</h2> 
                  </a>
                </li>
              </ul>
            </Col>
            <Col sm={4} md={4} lg={2}>
              <p style={{letterSpacing:"1px"}} className={styles.headingsFooter}>Contact</p>
              <a href="tel:+1-800-801-4801" style={{ color: '#52606D', textDecoration: 'none' }}>
                {/* <div className=''> */}
                  <p>
                    <Icon Icon={faPhone} color={'#6c757d'} style={{marginRight:8, width:17}} />
                    +1-800-801-4801
                  </p>
                {/* </div> */}
              </a>
              <a href="mailto:info@webhr.co" style={{ color: '#52606D', textDecoration: 'none' }}>
                <p>
                  <Icon Icon={faEnvelope} color={'#6c757d'} style={{ marginRight: 10, width:17 }} />
                  info[at]web.hr
                </p>
              </a>
              <p className={styles.headingsFooter}>Follow Us</p>
              <Link aria-label='facebook' href={'https://www.facebook.com/WebHR?mibextid=ZbWKwL'} target="_blank">
                <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/facebook.svg" aria-label='facebook' style={{ marginRight: 10, width:20, height:20 }} />
              </Link>
              <Link aria-label="twitter"  href={'https://twitter.com/webhrco?lang=en'} target="_blank">
                <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/twitter.svg"  aria-label='facebook' style={{ marginRight: 10, width:20, height:20 }} />
              </Link>
              <Link aria-label='linkedin' href={'https://www.linkedin.com/company/webhr/'} target="_blank">
                <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/linkedin.svg"  aria-label='facebook' style={{ marginRight: 10, width:20, height:20 }} />
              </Link>
              <Link aria-label='instagram' href={'https://instagram.com/webhr.co?igshid=ZWIzMWE5ZmU3Zg=='} target="_blank">
                <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/instagram.svg"  aria-label='facebook' style={{ marginRight: 10, width:20, height:20 }} />
              </Link>
            </Col>
          </Row>
          
          <hr style={{marginTop:"2em",}} />
            
          <Row style={{ marginTop:"2em", marginBottom:25 }}>
            {footerBottomMenu.map((item, index) => (
              <Col sm={4} md={4} lg={item.col}  key={index} style={{ display:"flex", flexDirection:"column", alignItems:"center", }} >
                <div style={{  width:"100%"}}>
                    <div>
                      <p style={{letterSpacing:"1px"}} className={styles.headingsFooter}>{item.heading}</p>
                      <ul className={styles.footerUl}>
                        {item.menu.map((innerItem, innerIndex) => (
                          <li key={innerIndex}>
                            <Link style={{ padding: '1em 0', }} href={innerItem.path} >
                              <h2 style={{ lineHeight:"1.8"}} >{innerItem.label}</h2>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
        <div className={styles.copyright}>
          <Container>
            <Col>
              <p>Copyright © 2024 by Verge Inc. All Rights Reserved.</p>
            </Col>
          </Container>
        </div>
      </div>

    </>
  )
}

export default Footer
